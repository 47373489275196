import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import brent from "../img/profile/brent.jpg"
import kim from "../img/profile/kim.jpg"
import charlotte from "../img/profile/charlotte.jpg"
import { colours, device } from "../components/constants"

import SEO from "../components/seo"

const ProfilePage = () => (
  <Layout>
    <SEO title="About Us" />

    <h1>About Candeo Design</h1>

    <p>
      <strong>Candeo Design</strong> is a landscape design company dedicated and
      passionate about creating stunning, user friendly and unique gardens and
      outdoor spaces.
    </p>

    <p>
      All designs are based on strong horticultural practices as well as
      creative use of quality and interesting products throughout the garden.
      Strong colour and texture is a common theme throughout Candeo Design
      gardens creating beautiful visual effects.
    </p>

    <p>
      These design theories are the corner stone of Candeo Design and will help
      make your new garden "<em>shine, glow, grow brilliant</em>".
    </p>

    <h2>Our Team</h2>

    <h3>Brent (MCIHort)</h3>

    <p>
      <img src={brent} alt="Brent" />
    </p>

    <p>
      Candeo Design was set up in 2006 by Brent Reid. Brent has worked in
      the landscaping industry for 22 years learning the ropes at Semken
      Landscaping, whilst studying Horticulture at Burnley College and
      Holmesglen College. During his time at Semken Landscaping Brent worked
      in both the garden construction and the garden maintenance sides of
      the company as well as taking the leading hand roll on some
      specialized projects. Brent was lucky enough to be involved in the
      construction of some amazing Show Gardens at the Melbourne
      International Flower and Garden Show working with some of Melbourne
      (and Australia's) best Landscape Designers. This is where Brent’s
      passion for Landscape Design was born. Brent moved into Landscape
      Design working under Jim Fogarty at Jim Fogarty Design for just over 3
      years. During his time at JFD Brent had the opportunity to be involved
      with drafting designs of numerous Show Gardens as well as designing
      gardens for Backyard Blitz and worked as a design advisor on Burkes
      Backyard.
    </p>

    <p>
      Spreading his wings in 2006 Brent started Candeo Design. "Candeo" is a
      Latin word meaning "To shine white" or "Shine, Glow, Grow Brilliant"
      this has become the mantra for Candeo Design and the gardens it
      creates.
    </p>

    <p>
      Brent returned late in 2009 from a 3 year stint in the United Arab
      Emirates designing and constructing signature pools and landscapes
      throughout the UAE. Brent was lucky enough to be commissioned to
      design gardens and pools on Palm Jumeirah (The Palm Island) and in
      Emirates Hills as well as many of the best addresses in the UAE.
      Sharjah Botanical Museum, Sharjah English School and the Oundle School
      in Dubai where amongst some of the projects Brent put his name to
      while in the UAE. Whilst in the United Arab Emirates Brent wrote a
      weekly garden article for Emirates Business 24/7 Newspaper and
      articles for YOU! magazine.
    </p>

    <p>
      Since returning to Melbourne Brent has been fortunate enough to design
      gardens at Melbourne International Flower and Garden Show, Australian
      Garden Show – Sydney and Malaysia Garden Festival where he has been
      awarded the full range of medals, Gold Silver and Bronze as well as
      “Best Use of Plantlife” at the Melbourne Show. Candeo design has been
      Lucky enough to design gardens at Garden Shows for Momentum Energy,
      Northern Territory Tourism, Family Life and Scotts Australia. While
      designing show gardens has been fun and a great way to show his
      talents to the general public Brent has designed dozens of gardens for
      his residential client base that have been completed, some have been
      featured in magazines such as House and Garden, Home Beautiful,
      Outdoor Design to name a few. Some have been opened for Victorian Open
      Garden Scheme and Garden DesignFest. While some gardens have made
      television appearances on programs such as Gardening Australia and
      Backyard Blitz.{" "}
    </p>
    <p>
      In recent years Brent has had the fun task of design gardens for
      numerous schools such as Forest Hills College, Our Lady of Perpetual
      Help Primary School in Ringwood, St Thomas More’s Primary School in
      Belgrave, Holy Spirit Primary School in North Ringwood, Montmorency
      College and St Leonards College in Sandringham. Candeo Design has also
      designed many gardens as part of larger school projects alongside the
      Victorian Education Department.
    </p>
    <p>
      As well as being one of Melbourne's most prolific Landscape Designers
      Brent has extended his skills to becoming a judge of the Landscape
      Victoria ‘Industry Awards’ for landscape design. In late 2017 Brent
      embarked on his first Garden Tour as a tour leader with Ross Garden
      Tours (RGT) to Japan in Autumn and looks forward to leading many more
      tours with RGT in the coming years. Brent has donated his time and
      skills in landscape design and horticulture to supply garden designs
      for the Starlight Foundation Gala Ball (2016 and 2017) to be auctioned
      to raise money to grant Starlight Wishes to sick children.
    </p>

  </Layout>
)
export default ProfilePage
